/** @jsxImportSource @emotion/react */
import React from 'react';
import { Carousel, CarouselProvider, useCarousel } from '../../components/Carousel/Carousel';
import { CarouselNavigationButton } from '../../components/Carousel/CarouselNavigationButton';
import { CarouselPagination } from '../../components/Carousel/CarouselPagination';
import { Page } from '../../components/page';
import t from '../../theme/styles';

export function HomepageQuotes() {
    return (
        <div css={[t.bg_primary_1]}>
            <Page style={[t.pt_9, t.pb_9]}>
                <div css={[t.relative, t.flex, t.flex_col, t.justify_center, t.items_center, t.min_h('400px'), t.px_7, t.md([t.px_8])]}>
                    <CarouselProvider>
                        <Carousel>
                            <Quote
                                index={0}
                                quote="Colin has contributed to all areas and stages of product development as an artist, interactive designer, organizer, and engineer. Always willing to take on new
                    challenges and learn, he is the most consistent, dependable developer I have ever worked with."
                                cite="Matt Van Gorder, Creative Lead, 1 vs. 100"
                            />
                            <Quote
                                index={1}
                                quote="Despite starting from scratch, with no game engine, tools, or content pipeline –
                                Colin consistently found ways to work with what he had, build what he did not,
                                and bring the vision for 1 vs 100 to life on Xbox 360."
                                cite="Chuck Noble, Developer, 1 vs. 100"
                            />
                            <Quote
                                index={2}
                                quote="Colin is an amazing designer, an emphatic collaborator, and sets the gold
                                standard for his discipline."
                                cite="Anonymous Peer, Amazon"
                            />
                            <Quote
                                index={3}
                                quote="In my opinion, he is the glue to the team. Without him, this project wouldn't be held together quite the same. He has definitely earned my trust over the months."
                                cite="Anonymous Peer, Amazon"
                            />
                            <Quote
                                index={4}
                                quote="In everything from systems design to studio culture, Colin has a very broad, analytical perspective. He excels in helping others to define and understand the underlying goals and motivations behind what they want to do, often resulting in a fundamental tilt towards a better product."
                                cite="Anonymous Peer, Amazon"
                            />
                        </Carousel>
                        <CarouselPagination />

                        <div css={[t.absolute, t.inset_0, t.z_10, t.flex, t.flex_row, t.text_tint_1]}>
                            <div css={[t.flex_initial, t.flex, t.flex_col, t.justify_center]}>
                                <CarouselNavigationButton direction="previous" />
                            </div>
                            <div css={[t.flex_auto]} />
                            <div css={[t.flex_initial, t.flex, t.flex_col, t.justify_center]}>
                                <CarouselNavigationButton direction="next" />
                            </div>
                        </div>
                    </CarouselProvider>
                </div>
            </Page>
        </div>
    );
}

function Quote(props: { index: number; quote: string; cite?: string }) {
    const { activeIndex } = useCarousel();

    return (
        <figure css={[t.max_w('540px'), props.index !== activeIndex ? t.hidden : [t.flex, t.flex_col]]}>
            <blockquote css={[t.font_heading, t.text_tint_1, t.type_3, t.text_center, t.md([t.type_4])]}>&ldquo;{props.quote}&rdquo;</blockquote>
            {props.cite && (
                <figcaption css={[t.mt_3, t.flex, t.flex_col, t.items_end]}>
                    <cite css={[t.text_tint_1, t.font_body, t.not_italic, t.type_1, t.text_right, t.sm([t.mr_4]), t.md([t.mr_6, t.type_2]), t.lg(t.mr_8)]}>
                        &mdash;&nbsp;{props.cite}
                    </cite>
                </figcaption>
            )}
        </figure>
    );
}
